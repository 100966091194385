<template>
    <mobile-float-menu>
        <template v-slot:content>
            <div class="row ecart-session justify-content-center bg-black">
                <div class="col-12 col-sm-8 col-md-6 d-flex flex-column justify-content-center ecart-session-content">
                    <div class="p-2 p-lg-5 m-xl-4 animated fadeInUp">
                        <h4 class="text-center f-w-600" v-text="$t('messages.toContinueIntegration')"></h4>
                        <p class="text-center">
                            <span class="btn text-success p-0" v-text="$t('login.login')"
                                @click=" currentSection = 'login' ">
                            </span>
                            <span> / </span>
                            <span class="btn text-success p-0" v-text=" $t('header.signup') "
                                @click=" currentSection = 'signup' ">
                            </span>
                        </p>
                        <hr class="w-50">
                        <Login onlyLoign v-show=" currentSection == 'login' " />
                        <Signup onlyLoign v-show=" currentSection == 'signup' " />
                    </div>
                </div>
            </div>
        </template>
    </mobile-float-menu>
</template>

<script>
    import MobileFloatMenu from '@/components/MobileFloatMenu';
    import Login from '@/components/Login';
    import Signup from '@/components/Signup';
    export default {
        components: {
            MobileFloatMenu,
            Login,
            Signup,
        },
        data() {
            return {
                currentSection: 'login',
            }
        },
        methods: {},
        mounted() {
            window.location.href = '/'; // TODO: Remove this line if is necessary
        }
    }
</script>

<style lang="scss">
    .ecart-session {
        min-height: 100vh;

        .ecart-session-content {
            background-color: white;
        }

        @media only screen and (max-width: 767px) {
            background-image: url('../../assets/images/backgrounds/bg-2.png');
            background-size: cover;

            .ecart-session-content {
                background-color: transparent;
                color: white !important;
            }
        }


    }
</style>