<template>
  <div class="form-rounded-inputs">
    <div class="row mb-3 align-items-center">
      <div class="col-3">
      </div>
      <div class="col-6 text-center">
        <router-link to="/">
          <img src="@/assets/images/logo-ecart.svg" class=" img-100 d-none d-md-inline-block" alt="">
          <img src="@/assets/images/ecart-logo.png" class=" img-contain-150 d-md-none" alt="">
        </router-link>
      </div>
      <div class="col-3 text-right">
        <Languages variant='none p-0' :class=" mobileVerison ? 'd-none d-md-block' : '' " />
      </div>
    </div>

    <b-alert variant="danger" dismissible fade :show="showAlert.email">
      <i class=" fa fa-warning "></i> <span v-text=" $t('signup.errorEmail') "> </span>
    </b-alert>

    <v-observer class="form-dark-mobile" ref="myform" tag="form" @submit.prevent="fnSignup">

      <h4 class="text-center mb-3" v-if=" !onlyLoign ">
        <strong class="f-w-700" v-text=" $t('signup.title') "></strong>
      </h4>

      <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.country') ">
        <div class="form-group">
          <!-- <label for="" v-text="$t('general.form.country')"></label> -->
          <div class="input-group " :class=" { 'is-invalid ':errors.length > 0 } ">
            <div class="input-group-prepend d-md-none">
              <span class="input-group-text">
                <i class="fa fa-key"></i>
              </span>
            </div>
            <select class="custom-select" v-model="user.country" :class=" { 'is-invalid ':errors.length > 0 } ">
              <option :value="null" selected v-text=" $t('general.form.country') ">
              </option>
              <option :value="country.code" v-for=" country in countriesList  " :key="country.name"
                v-text="country.name">
              </option>
            </select>
            <div class="input-group-append">
              <label class="input-group-text">
                <i class="fa fa-flag"></i>
              </label>
            </div>
            <div class="invalid-feedback" v-if="errors.length > 0">
              {{ errors[0] }}
            </div>
          </div>

        </div>
      </v-validation>

      <v-validation rules="required" v-slot="{ errors }" :name=" $t('signup.email') ">
        <div class="form-group ">
          <!-- <label for="" v-text="$t('signup.email')"> </label> -->
          <div class="input-group " :class="{ 'is-invalid': errors.length > 0 }">
            <div class="input-group-prepend d-md-none ">
              <span class="input-group-text ">
                <strong>
                  <i class=" fa fa-envelope "></i>
                </strong>
              </span>
            </div>
            <input v-model="user.email" class="form-control" type="email" name="Email"
              :placeholder=" $t('signup.email') " :class="{ 'is-invalid': errors.length > 0 }"
              @focus=" showAlert.email = false " />
            <div class="input-group-append">
              <span class="input-group-text ">
                <strong>
                  <i class=" fa fa-envelope "></i>
                </strong>
              </span>
            </div>
            <div class="invalid-feedback" v-if="errors.length > 0">
              {{ errors[0] }}
            </div>
          </div>
        </div>
      </v-validation>

      <div class="form-group">
        <!-- <label class="col-form-label" v-text="$t('signup.password')"> </label> -->
        <v-validation rules="required|min:8" v-slot="{ errors }" :name=" $t('signup.password') ">
          <div class="input-group" :class="{ 'is-invalid': errors.length > 0 }">
            <div class="input-group-prepend d-md-none ">
              <span class="input-group-text ">
                <strong>
                  <i class=" fa fa-key "></i>
                </strong>
              </span>
            </div>
            <input v-model="user.password " class="form-control" type="password"
              :placeholder=" $t('signup.password')"
              :class=" { 'is-invalid':errors.length > 0 } ">
            <div class="input-group-append ">
              <span class="input-group-text ">
                <strong>
                  <i class=" fa fa-key "></i>
                </strong>
              </span>
            </div>
            <div class="invalid-feedback" v-if="errors.length > 0">
              {{ errors[0] }}
            </div>
          </div>


        </v-validation>
      </div>

      <div class="form-group">
        <!-- <label class="col-form-label" v-text="$t('signup.password_confirmation')"> </label> -->
        <v-validation rules="required|min:8" v-slot="{ errors }" :name=" $t('signup.password_confirmation') ">
          <div class="input-group" :class="{ 'is-invalid': errors.length > 0 }">
            <div class="input-group-prepend d-md-none ">
              <span class="input-group-text ">
                <strong>
                  <i class=" fa fa-key "></i>
                </strong>
              </span>
            </div>
            <input v-model="user.password_confirmation " class="form-control" type="password"
              :placeholder=" $t('signup.password_confirmation') "
              :class=" { 'is-invalid':errors.length > 0 } ">
            <div class="input-group-append ">
              <span class="input-group-text ">
                <strong>
                  <i class=" fa fa-key "></i>
                </strong>
              </span>
            </div>
            <div class="invalid-feedback" v-if="errors.length > 0">
              {{ errors[0] }}
            </div>
          </div>
        </v-validation>
      </div>

      <b-alert variant="danger" fade
        :show=" (user.password && user.password_confirmation) && (user.password != user.password_confirmation) && ( user.password.length > 0 && user.password_confirmation.length > 0 )  ">
        <i class=" fa fa-warning "></i> <span v-text=" $t('signup.errorPass') "> </span>
      </b-alert>

      <v-validation :rules="{ required: { allowFalse: false } }" v-slot="{ errors }" :name="$t('signup.terms')">
        <div class="form-group form-check my-3 text-center my-2">
          <label class="form-check-label" for="termsConditions">
            <input type="checkbox" class="form-check-input mt-1" id="termsConditions" v-model="user.terms ">
            <span v-text=" $t('signup.message') "></span>
            <router-link to="/terms-&-conditions" v-text=" $t('signup.terms') "> </router-link>
          </label>
        </div>
        <p class=" text-danger animated text-left  " :show=" errors.length > 0 "
          :class=" (errors.length > 0) ? 'fadeIn':' d-none ' ">
          <i class=" fa fa-warning mr-2"></i> <span v-text=" $t('signup.errorTerms') "> </span>
        </p>
      </v-validation>

      <div class="d-md-none">
        <button class=" btn btn-success w-100 mt-2" :disabled=" loading.data ">
          <span v-text=" $t('signup.signup') " v-if=" !loading.data "></span>
          <i class="fa fa-spinner fa-spin " v-else></i>
        </button>
      </div>

      <div class="row mt-4 d-none d-md-flex">
        <div class="col-12" v-if=" !onlyLoign ">
          <p>
            <span v-text=" $t('signup.question') "> </span>
            <router-link to="login">
              <strong class="f-w-600" v-text=" $t('signup.login') "></strong>
            </router-link>
          </p>
        </div>
        <div class="col-auto ml-auto">
          <button class=" btn btn-primary " :disabled=" loading.data ">
            <span v-text=" $t('signup.signup') " v-if=" !loading.data "></span>
            <i class="fa fa-spinner fa-spin " v-else></i>
          </button>
        </div>
      </div>

    </v-observer>

  </div>
</template>

<script>
  import {
    mapState,
    mapActions,
  } from "vuex";
  import Languages from "@/components/Languages";
  export default {
    props: {
      onlyLoign: {
        type: Boolean,
        default: false,
      },
      mobileVerison: {
        type: Boolean,
        default: false,
      }
    },
    components: {
      Languages,
    },
    data() {
      return {
        countriesList: [],
        user: {
          terms: false,
          email: null,
          password: null,
          country: null,
          password_confirmation: null,
        }
      };
    },
    computed: {
      ...mapState("session", ["loading", "showAlert"])
    },
    methods: {
      ...mapActions("session", ["fnApiSignup"]),
      async fnSignup() {
        if (await this.$refs.myform.validate()) {
          this.fnApiSignup({
            email: this.user.email,
            password: this.user.password,
            country: this.user.country,
          });
        }
      },
    },
    async mounted() {
      this.countriesList = await this.fnApiGetCountries();
      if(this.$route.query.email){
        this.user.email = this.$route.query.email
      }
      if(this.$route.query.country){
        this.user.country = this.$route.query.country
      }
    }
  }
</script>